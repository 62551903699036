<template id="teenpatti">
  <b-container>
    <template v-if="ispreview">
        <b-row>
        <b-col cols="6">
          <h4>Teen Patti Table Preferences</h4>
        </b-col>
      </b-row>
      <slot name="previewadditionaloptions"> </slot>
      <b-row>
        <b-col cols="6">
          <label>Max Players</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.maxPlayers }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <label>Deal for Seat Position</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.mustSeatDeal }}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Min Chaal</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.minPot}}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Max Chaal</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.maxPot }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Boot</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.ante }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Dealer Boot</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.dealerBoot }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Compulsory Blind</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.compulsoryBlind }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Max Blinds</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.maxBlinds }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Salaami For Trail</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.salaamiTrail }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Salaami For Picture Trail</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.salaamiPictureTrail }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Salaami For Ace Trail</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.salaamiAceTrail }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Disable Video</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.disableAudioVideo }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Hide Audio/Video controls</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ tpvalue.hideAVControls }}
          </div>
        </b-col>
      </b-row>
    </template>

    <template v-else>
      <b-row>
        <b-col cols="6">
          <h4>Teen Patti Table Rules</h4>
        </b-col>
      </b-row>
      <slot name="additionaloptions"> </slot>
      <b-row>
        <b-col>
          <b-form-group label="Pick Cards to Decide Seating" label-for="mustseatdeal">
            <b-form-checkbox
              id="mustseatdeal"
              v-model="tpvalue.mustSeatDeal"
              name="mustseatdeal"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Pot Configurations -->
      <b-row>
        <b-col cols="20">
          <h5>Min/Max Chaal allowed (Min blind will be half of Min Chaal)</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Min Chaal" label-for="minPot">
            <b-form-input
              id="minPot"
              type="number"
              v-model.number="tpvalue.minPot"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Max Chaal" label-for="maxPot">
            <b-form-input
              id="maxPot"
              type="number"
              v-model.number="tpvalue.maxPot"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <h5>Boot</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Each Player Boot" label-for="ante">
            <b-form-input
              id="ante"
              type="number"
              v-model.number="tpvalue.ante"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Additional Dealer Boot" label-for="dealerBoot">
            <b-form-input
              id="dealerBoot"
              type="number"
              v-model.number="tpvalue.dealerBoot"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <h5>Blinds Configuration</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Compulsory Blind" label-for="compulsoryBlind">
            <b-form-checkbox
              id="compulsoryBlind"
              v-model="tpvalue.compulsoryBlind"
              name="compulsoryBlind"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Max Blind Rounds" label-for="maxBlinds">
            <b-form-input
              id="maxBlinds"
              type="number"
              v-model.number="tpvalue.maxBlinds"
            />
          </b-form-group>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col cols="20">
          <h5>Salaami for Trails (set to 0 for no salaami)</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Salaami for Trail" label-for="salaamiTrail">
            <b-form-input
              id="salaamiTrail"
              type="number"
              v-model.number="tpvalue.salaamiTrail"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Salaami for Picture Trail" label-for="salaamiPictureTrail">
            <b-form-input
              id="salaamiPictureTrail"
              type="number"
              v-model.number="tpvalue.salaamiPictureTrail"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Salaami for Ace Trail" label-for="salaamiAceTrail">
            <b-form-input
              id="salaamiAceTrail"
              type="number"
              v-model.number="tpvalue.salaamiAceTrail"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>
<script>
import DEBUG from "@/common/DEBUG";
import { BettingLimits, HiLoTypes, JokerTypes } from "@/grpcservices/Game_pb";
import { DealModes } from "@/grpcservices/Game_pb";
import { ShuffleModes } from "@/grpcservices/Game_pb";

const DEFAULT = {
  packsPerGame: 1,
  autoCalculatePacks: false,
  pictureJokersPerPack: 0,
  maxPlayers: 9,
  mustSeatDeal: true,

  cardsPerHand: 3,
  maxCardsPerHand: 3,
  maxSwap: 0,
  showHandOnTable: false,
  
  shuffleMode: ShuffleModes.FULL,
  manualDealMode: DealModes.FACEDOWN,
  autoDealMode: DealModes.FACEDOWN,
  isOpenTable:false,

  hasPot: true,
  minPot:10,
  maxPot: 100,
  ante: 0,
  dealerBoot: 10,
  compulsoryBlind: true,

  hiLoType: HiLoTypes.HIGHONLY,
  maxBlinds: 3,
  salaamiAceTrail: 100,
  salaamiPictureTrail: 50,
  salaamiTrail: 50,

  bettingLimit: BettingLimits.FIXEDLIMIT,

  hasDealTray: true,
  hasDiscardTray: false,
  hasDeclareTray: false,
  hasBustTray: false,
  hasBurnTray: false,
  hasCommunityTray:false,
  hasJokerTray: false,
  jokerType: JokerTypes.NOJOKER,
  hideAVControls: true,
  disableAudioVideo: false,
};

export default {
  name: "tpvalue",
  props: {
    ispreview: {
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
    },
    createNew: true,
    rules: {
      required: false,
    }
  },
  computed: {
    allowMore() {
      const that = this;
      var allow = true;
      allow = that.tpvalue.maxCardsPerHand > that.tpvalue.cardsPerHand;
      if (!allow)
        this.tpvalue.manualDealMode = DealModes.NONE;
      else {
        if (this.tpvalue.manualDealMode == DealModes.NONE)
          this.tpvalue.manualDealMode = DealModes.BOTH;
      }
      return allow;
    }
  },
  
  data: function() {
    return {
      JokerTypes: JokerTypes,
      DealModes: DealModes,
      ShuffleModes: ShuffleModes,
      tpvalue: this.$props.ispreview ? { ...this.$props.rules } : { ...DEFAULT }, 
    };
  },

  beforeMount(){
    // AV: we need to emit on mount in addition to when the value or tpvalue changes. 
    // emit when value changes is done by the watch fnction below. however if no change is
    // made then we end up saving the values already stored in gamedef in create-room.vue rather
    // than the default values for this game 
      this.$emit("input", this.tpvalue);
  },
  
  watch: {
    tpvalue: {
      deep: true,
      handler(newvalue) {
        this.$emit("input", newvalue);
      },
    },
  },
};
</script>