<template id="rummy">
  <b-container>
    <template v-if="ispreview">
      <slot name="previewadditionaloptions"> </slot>
          <b-row>
        <b-col cols="6">
          <h4>RUMMY Preferences </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Packs Per Game</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{ rummyvalue.packsPerGame }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Auto Calculate Packs</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{ rummyvalue.autoCalculatePacks }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Shuffle Mode</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.shuffleMode }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Max Players</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.maxPlayers }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <label>Cards auto dealt</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{ rummyvalue.cardsPerHand }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Auto deal mode</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{rummyvalue.autoDealMode }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Max Cards</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{ rummyvalue.maxCardsPerHand }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Manual deal mode</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{ rummyvalue.manualDealMode }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Deal for Seat Position</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.mustSeatDeal }}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Hand On Table </label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.showHandOnTable }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Max Swap Cards</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.maxSwap }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Deal Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.hasDealTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Discard Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.hasDiscardTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Declare Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.hasDeclareTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Bust Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.hasBustTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Burn Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.hasBurnTray }}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Community Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.hasCommunityTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Pot</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.hasPot }}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Min Bet</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.minPot}}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Max Bet</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.maxBet }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Joker Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.hasJokerTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Joker Type</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.jokerType }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Jokers Per Pack</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.pictureJokersPerPack }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Disable Video</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.disableAudioVideo }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Hide Audio/Video controls</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ rummyvalue.hideAVControls }}
          </div>
        </b-col>
      </b-row>
    </template>

    <template v-else>
      <b-row>
        <b-col>
          <h4>RUMMY Preferences </h4>
        </b-col>
      </b-row>
      <slot name="additionaloptions"> </slot>
      <!-- Deal Configurations -->
      <b-row>
        <b-col colspan="2">
          <h4>Deal Configurations</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Auto deal" label-for="cardsperhand">
            <b-form-input
              id="cardsperhand"
              type="number"
              v-model.number="rummyvalue.cardsPerHand"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Mode" label-for="autoDealMode">
            <b-form-select v-model="rummyvalue.autoDealMode">
              <option :value="DealModes.FACEUP">FACEUP</option>
              <option :value="DealModes.FACEDOWN">FACEDOWN</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Max Cards" label-for="maxCardsPerHand">
            <b-form-input
              id="maxCardsPerHand"
              type="number"
              v-model.number="rummyvalue.maxCardsPerHand"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Manual Deal Mode" label-for="manualDealMode">
            <b-form-select v-model="rummyvalue.manualDealMode"
              :disabled="!allowMore">
              <option :key="key" v-for="(key, value) in DealModes" :value="key">
                {{ value }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Joker Configurations -->
      <b-row>
        <b-col>
          <b-form-group label="Has Joker Tray" label-for="hasjokertray">
            <b-form-checkbox
              id="hasjokertray"
              v-model="rummyvalue.hasJokerTray"
              name="hasjokertray"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Joker Type" label-for="jokertype">
            <b-form-select v-model="rummyvalue.jokerType">
              >
              <option
                :key="key"
                v-for="(key, value) in JokerTypes"
                :value="key"
              >
                {{ value }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Jokers Per Pack"
            label-for="pictureJokersPerPack"
          >
            <b-form-input
              id="pictureJokersPerPack"
              type="number"
              v-model.number="rummyvalue.pictureJokersPerPack"
            />
          </b-form-group>
          <hr />
          <hr />
        </b-col>
      </b-row>

      <!-- AV Configurations -->
      <b-row>
        <b-col colspan="2">
          <h4>AV Configurations</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Disable Audio/Video"
            label-for="disableAudioVideo"
          >
            <b-form-checkbox
              id="disableAudioVideo"
              v-model="rummyvalue.disableAudioVideo"
              name="disableAudioVideo"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Hide AV Controls" label-for="hideAVControls">
            <b-form-checkbox
              id="hideAVControls"
              :disabled="rummyvalue.disableAudioVideo"
              v-model="rummyvalue.hideAVControls"
              name="hideAVControls"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>
<script>
import DEBUG from "@/common/DEBUG";
import { JokerTypes } from "@/grpcservices/Game_pb";
import { DealModes } from "@/grpcservices/Game_pb";
import { ShuffleModes } from "@/grpcservices/Game_pb";

const DEFAULT = {
  packsPerGame: 1,
  autoCalculatePacks: true,
  pictureJokersPerPack: 2,
  maxPlayers: 9,
  mustSeatDeal: true,

  cardsPerHand: 13,
  maxCardsPerHand: 13,
  maxSwap: 1,
  showHandOnTable: true,
  
  shuffleMode: ShuffleModes.FULL,
  manualDealMode: DealModes.NONE,
  autoDealMode: DealModes.FACEUP,
  isOpenTable:false,

  hasPot: false,
  minPot:0,
  maxPot: 0,
  ante: 0,
  
  hasDealTray: true,
  hasDiscardTray: true,
  hasDeclareTray: true,
  hasBustTray: false,
  hasBurnTray: false,
  hasCommunityTray:false,
  hasJokerTray: true,
  jokerType: JokerTypes.OPEN,
  hideAVControls: false,
  disableAudioVideo: false,
};

export default {
  name: "rummyvalue",
  props: {
    ispreview: {
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
    },
    createNew: true,
    rules: {
      required: false,
    }
  },
  computed: {
    allowMore() {
      const that = this;
      var allow = true;
      allow = that.rummyvalue.maxCardsPerHand > that.rummyvalue.cardsPerHand;
      if (!allow)
        this.rummyvalue.manualDealMode = DealModes.NONE;
      else {
        if (this.rummyvalue.manualDealMode == DealModes.NONE)
          this.rummyvalue.manualDealMode = DealModes.BOTH;
      }
      return allow;
    }
  },
  
  data: function() {
    return {
      JokerTypes: JokerTypes,
      DealModes: DealModes,
      ShuffleModes: ShuffleModes,
      rummyvalue: this.$props.ispreview ? { ...this.$props.rules } : { ...DEFAULT }, 
    };
  },

  beforeMount(){
    // AV: we need to emit on mount in addition to when the value or rummyvalue changes. 
    // emit when value changes is done by the watch fnction below. however if no change is
    // made then we end up saving the values already stored in gamedef in create-room.vue rather
    // than the default values for this game 
      this.$emit("input", this.rummyvalue);
  },
  
  watch: {
    rummyvalue: {
      deep: true,
      handler(newvalue) {
        this.$emit("input", newvalue);
      },
    },
  },
};
</script>