const appconfig = {
	autosignin: false,

	gametypes: [
		{
			name: 'rummy',
			display: 'Rummy',
			image: '/images/games/game-rummy-img.png',
			active: true
		},
		{
			name: 'teenpatti',
			display: 'Teen Patti',
			image: '/images/games/game-teenpatti-img.png',
			active: true
		},
		{
			name: 'poker',
			display: 'poker',
			image: '/images/games/game-poker-img.png',
			active: true
		}
		// ,
		// {
		// 	name: 'other',
		// 	display: 'My Game',
		// 	image: '/images/games/game-mygame-img.png',
		// 	active: true
		// },
	],

	roomtypes: [
		{
			name: 'livingroom',
			display: 'livingroom',
			image: '/images/rooms/choose-room-01-living.png'
		},
		{
			name: 'gardenarea',
			display: 'gardenarea',
			image: '/images/rooms/choose-room-02-garden.png'
		},
		{
			name: 'terrace',
			display: 'terrace',
			image: '/images/rooms/choose-room-03-terrace.png'
		},
		/*{
			name: 'customroom1',
			display: 'Mycustomroom1',
			image: '/images/rooms/choose-room-04-myroom.png'
		},*/
	],

	tabletypes: [
		{
			name: 'onthefloor',
			display: 'onthefloor',
			image: '/images/tables/choose-table-01-onthefloor.png',
			notes: 'Recommended if you have more then 6 players',
		},
		{
			name: 'rectangle',
			display: 'rectangle',
			image: '/images/tables/choose-table-02-rectangle.png',
			notes: 'Recommended if you have more then 6 players',
		},
		{
			name: 'oval',
			display: 'oval',
			image: '/images/tables/choose-table-03-oval.png',
			notes: 'Recommended if you have more then 4 players',
		},
		{
			name: 'round',
			display: 'round',
			image: '/images/tables/choose-table-04-round.png',
			notes: 'Recommended if you have more then 6 players',
		},
	]
}

export default appconfig;