<template>
  <div class="create-room">
    <div class="content">
      <div class="custom-tab">
        <b-tabs v-model="activetabindex">
          <b-tab active>
            <template #title>
              <div class="point">1</div>
              <div class="tab-title">
                <img src="../assets/images/icon-001-choose-room-type.png" />
                <p>Choose Game</p>
              </div>
            </template>
            <div class="tab-container">
              <div class="select-images"> 
                <div
                  class="checkbox"
                  v-for="(game, gameIndex) in GAMES"
                  :key="'game' + gameIndex"
                >
                  <input
                    type="radio"
                    :id="'game' + gameIndex"
                    name="game-type"
                    v-model="gamedef.gametype"
                    :value="game.name"
                    :disabled="!game.active"
                    @click="setGameType(game.name)"
                  />
                  <label :for="'game' + gameIndex" :disabled="!game.active">
                    <img :src="game.image" />
                  </label>
                  <!-- <p>{{ game.displayname }}</p> -->
                </div>
              </div>

              <div class="button-group">
                <b-btn class="game-button" @click="++activetabindex">
                  Next <img src="../assets/images/right.svg" />
                </b-btn>
              </div>
            </div>
          </b-tab>
          <b-tab lazy>
            <template #title>
              <div class="point">2</div>
              <div class="tab-title">
                <img src="../assets/images/icon-create-room-004-rules.png" />
                <p>Game Rules</p>
              </div>
            </template>
            <div class="tab-container" style="position:relative;top:0px">
              <div class="tab-form">
                <!-- <h4 class="heading">Game Rules</h4> -->
                <component
                  :is="gamerulescomponent"
                  v-model="gamedef.rules"
                  style="height: 95%; overflow-y: auto"
                  :createNew="createNew"
                >
                  <template v-slot:additionaloptions>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Name your Game"
                          label-for="name"> 
                          <b-form-input
                            id="name"
                            v-model="gamedef.name"
                            name="name"
                            />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Currency Type"
                          label-for="currencyType"
                        >
                          <b-form-select v-model="gamedef.currency">
                            <option value="SnapCoins">Points</option>
                            <option value="Points">Points</option>
                            <!--<option value="INR">INR</option>-->
                            <!--<option value="USD">USD</option>-->
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col v-if="isuser_admin">
                        <b-form-group
                          label="Is Published"
                          label-for="isPublished"
                        >
                          <b-form-checkbox
                            id="isPublished"
                            v-model="gamedef.isPublished"
                            name="isPublished"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                </component>
              </div>
              <div class="button-group mt-3 pt-3" style="position:relative;top:-25px;">
                <b-btn class="game-button back" @click="--activetabindex">
                  <img src="../assets/images/right.svg" /> Back
                </b-btn>
                <b-btn class="game-button" @click="++activetabindex">
                  Next <img src="../assets/images/right.svg" />
                </b-btn>
              </div>
            </div>
          </b-tab>
          <b-tab lazy>
            <template #title>
              <div class="point">3</div>
              <div class="tab-title">
                <img src="../assets/images/game-preview-01.png" />
                <p>Game Preview</p>
              </div>
            </template>
            <div class="tab-container">
              <div class="save-game tab-form">
                <div class="game-preview">
                  <div class="game-background">
                    <img
                      src="../assets/images/background.png"
                      alt="background"
                    />
                  </div>
                  <div class="game-table">
                    <img src="../assets/images/table.png" alt="table" />
                  </div>
                </div>
                <div class="game-rules">
                  <h3 class="heading">Game Rules</h3>
                  <component 
                    :is="gamerulescomponent"
                    v-model="gamedef.rules"
                    :ispreview="true"
                    :createNew="createNew"
                    :rules="gamedef.rules"
                    style="height:95%; overflow-y: auto"
                  >
                    <template v-slot:previewadditionaloptions>
                      <b-row>
                        <b-col cols="6">
                          <label>Name</label>
                        </b-col>
                        <b-col cols="6" class="text-right">
                          <div>
                            {{ gamedef.name }}
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="6">
                          <label>Type</label>
                        </b-col>
                        <b-col cols="6" class="text-right">
                          <div>
                            {{gamedef.gametype}}
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="6">
                          <label>Currency</label>
                        </b-col>
                        <b-col cols="6" class="text-right">
                          <div>
                            {{gamedef.currency}}
                          </div>
                        </b-col>
                      </b-row>
                      <b-row v-if="isuser_admin">
                        <b-col cols="6">
                          <label>Is Published</label>
                        </b-col>
                        <b-col cols="6" class="text-right">
                          <div>
                            {{ gamedef.isPublished }}
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </component>
                </div>
              </div>
              <div class="button-group">
                <b-btn class="game-button back" @click="--activetabindex">
                  <img src="../assets/images/right.svg" /> Back
                </b-btn>
                <b-btn class="game-button" @click="save(false)"
                  >Save Game</b-btn
                >
                <b-btn
                  class="gold-button"
                  v-if="isuser_admin || isuser_gamehost"
                  @click="save(true)"
                  >Save and Continue</b-btn
                >
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script language="javascript">
import { mapGetters, mapActions } from "vuex";
import rummy from "./rules/rummy-rules";
import teenpatti from "./rules/teenpatti-rules";
import poker from "./rules/poker-rules";
import other from "./rules/default-rules";
import DEBUG from "@/common/DEBUG";
import appconfig from "@/config/appconfig";
import { JokerTypes } from "@/grpcservices/Game_pb";
import { DealModes } from "@/grpcservices/Game_pb";
import { ShuffleModes } from "@/grpcservices/Game_pb";
import { GameType } from "@/grpcservices/Game_pb";
import { HiLoTypes } from "@/grpcservices/Game_pb";
import { BettingLimits } from "@/grpcservices/Game_pb";

export default {
  name: "createroom",
  components: {
    rummy,
    teenpatti,
    poker,
    other,
  },

  data: function() {
    return {
      activetabindex: 0,
      gamedef: {
        id: "",
        name: "MyGame",
        gameType: GameType.RUMMY,
        gameVariant: 0,
        currency: "SnapCoins",
        isPublished: false, 
        environment: {
          tableType: "oval",
          roomType: "gardenarea",
          cardType: "blue",
        },
        rules: {
          packsPerGame: 1,
          autoCalculatePacks: false,
          pictureJokersPerPack: 0,
          maxPlayers: 9,
          mustSeatDeal: true,

          cardsPerHand: 13,
          maxCardsPerHand: 13,
          maxSwap: 1,
          showHandOnTable: true,
          
          shuffleMode: ShuffleModes.FULL,
          manualDealMode: DealModes.BOTH,
          autoDealMode: DealModes.FACEDOWN,
          isOpenTable:false,

          hasPot: false,
          minPot:10,
          maxPot: 100,
          ante: 0,
          
          dealerBoot: 10,
          compulsoryBlind: true,

          hiLoType: HiLoTypes.HIGHONLY,
          maxBlinds: 3,
          salaamiTrail: 50,
          salaamiPictureTrail: 50,
          salaamiAceTrail: 100,

          bettingLimit: BettingLimits.FIXEDLIMIT,

          hasDealTray: true,
          hasDiscardTray: true,
          hasDeclareTray: true,
          hasBustTray: false,
          hasBurnTray: false,
          hasCommunityTray:false,
          hasJokerTray: true,
          jokerType: JokerTypes.OPEN,
          hideAVControls: true,
          disableAudioVideo: false,
        },
      },
    };
  },

  computed: {
    GAMES() {
      return appconfig.gametypes;
    },
    ROOMS() {
      return appconfig.roomtypes;
    },
    TABLES() {
      return appconfig.tabletypes;
    },
    createNew(){
      return this.gamedef?.id ? false : true;
    },
    gamerulescomponent() {
      const that = this;
      let rulesTable = that.gamedef?.gametype ?? "other";
    return rulesTable;
    },

    ...mapGetters(["isuser_admin", "isuser_gamecreator", "isuser_gamehost"]),
  },

  async mounted() {
    const that = this;
    const id = (that.id = that.$route.params.id);
    if (id) {
      Object.assign(that.gamedef, await that.getGameDefinition(id));
    }
  },

  methods: {
    ...mapActions([
      "getGameDefinition",
      "insertGameDefinition",
      "updateGameDefinition",
      "createGame",
    ]),
    setGameType (gameName) {
      DEBUG.log ("in setGameType gamename", gameName);
      this.gamedef.name = gameName;
      if (gameName == "rummy")
        this.gamedef.gameType = GameType.RUMMY;
      else if (gameName == "teenpatti")
        this.gamedef.gameType = GameType.TEENPATTI;
      else if (gameName == "poker")
        this.gamedef.gameType = GameType.POKER;
      else
        this.gamedef.gameType = GameType.RUMMY; // there should be an 'other' game type
    },
    async save(canContinue) {
      const that = this;
      try {
        const gamedef = that.gamedef;
        const gamedefid = gamedef.id;
        if (gamedef.gameType == GameType.POKER) {
          // modify game name to incorporate big blind and ante if any
          if (!gamedef.name.includes("BB")) {
            gamedef.name += " BB=" + gamedef.rules.minPot;
          }
          if (gamedef.rules.ante > 0 && !gamedef.name.includes("Ante")) {
            gamedef.name += " Ante=" + gamedef.rules.ante;
          }  
        }
        else if (gamedef.gameType == GameType.TEENPATTI) {
          // modify game name to incorporate min and max chaal amounts
          if (!gamedef.name.includes("Min Chaal")) {
            gamedef.name += " Min Chaal=" + gamedef.rules.minPot;
          }
          if (gamedef.rules.maxPot > 0 && !gamedef.name.includes("Max Chaal")) {
            gamedef.name += " Max Chaal=" + gamedef.rules.maxPot;
          }  
        }

        DEBUG.log("SAVING GAME CONFIG - ", gamedef);
        const id = gamedefid
          ? await that.updateGameDefinition(gamedef)
          : await that.insertGameDefinition(gamedef);
        DEBUG.log("SAVED GAME CONFIG - ", id);

        // store the id
        gamedef.id = id;

        // NOTE: when listing is there, we should be able to do...
        if (canContinue) {
          const game = await that.createGame(id);
          DEBUG.log("CREATED A NEW GAME - ", game);
          await that.$router.push({ name: "play", params: { id: game.id } });
        }
        else 
          await that.$router.push({ name: "home"});

        await that.notifySuccess(`game saved`);
      } catch (error) {
        DEBUG.log("ERROR in save game - %o", error);
        that.notifyError(
          `Unable to save game due to an error - ${error.message}`
        );
      }
    },
  },
};
</script>