<template id="poker">
  <b-container>
    <template v-if="ispreview">
      <slot name="previewadditionaloptions"> </slot>
      <b-row>
        <b-col cols="6">
          <h4>POKER Preferences</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Max Players</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ pokervalue.maxPlayers }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <label>Deal for Seat Position</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ pokervalue.mustSeatDeal }}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Big Blind</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ pokervalue.minPot}}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Ante</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ pokervalue.ante }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Disable Video</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ pokervalue.disableAudioVideo }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Hide Audio/Video controls</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ pokervalue.hideAVControls }}
          </div>
        </b-col>
      </b-row>
    </template>
    <template v-else>
     <b-row>
        <b-col>
          <h4>POKER Table Rules</h4>
        </b-col>
      </b-row> <slot name="additionaloptions"> </slot>

      <!-- Deal Configurations -->
      <b-row>
        <b-col>
          <b-form-group label="Pick Card to seat" label-for="mustseatdeal">
            <b-form-checkbox
              id="mustseatdeal"
              v-model="pokervalue.mustSeatDeal"
              name="mustseatdeal"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Big Blind" label-for="minPot">
            <b-form-input
              id="minPot"
              type="number"
              v-model.number="pokervalue.minPot"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Ante" label-for="ante">
            <b-form-input
              id="ante"
              type="number"
              v-model.number="pokervalue.ante"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col style="max-width:50%">
          <b-form-group label="Betting Limit Type (can be changed during game)" label-for="bettingLimit">
            <b-form-select v-model="pokervalue.bettingLimit">
              <option :value="BettingLimits.FIXEDLIMIT">Fixed Limit</option>
              <option :value="BettingLimits.POTLIMIT">Pot Limit</option>
              <option :value="BettingLimits.NOLIMIT">No Limit</option>
            </b-form-select>
          </b-form-group>
        </b-col>
      <b-row>

    </template>
  </b-container>
</template>
<script>
import DEBUG from "@/common/DEBUG";
import { BettingLimits, HiLoTypes, JokerTypes } from "@/grpcservices/Game_pb";
import { DealModes } from "@/grpcservices/Game_pb";
import { ShuffleModes } from "@/grpcservices/Game_pb";

const DEFAULT = {
  packsPerGame: 1,
  autoCalculatePacks: false,
  pictureJokersPerPack: 0,
  maxPlayers: 9,
  mustSeatDeal: true,

  cardsPerHand: 2,
  maxCardsPerHand: 2,
  maxSwap: 0,
  showHandOnTable: false,
  
  shuffleMode: ShuffleModes.FULL,
  manualDealMode: DealModes.FACEUP,
  autoDealMode: DealModes.FACEUP,
  isOpenTable:false,

  hasPot: true,
  minPot:10,
  maxPot: 0,
  ante: 0,
  dealerBoot: 0,
  compulsoryBlind: false,

  hiLoType: HiLoTypes.HIGHONLY,
  maxBlinds: 0,
  salaamiAceTrail: 0,
  salaamiPictureTrail: 0,
  salaamiTrail: 0,

  bettingLimit: BettingLimits.FIXEDLIMIT,

  hasDealTray: true,
  hasDiscardTray: false,
  hasDeclareTray: false,
  hasBustTray: false,
  hasBurnTray: true,
  hasCommunityTray: true,
  hasJokerTray: false,
  jokerType: JokerTypes.NOJOKER,
  hideAVControls: false,
  disableAudioVideo: false,
};

export default {
  name: "pokervalue",
  props: {
    ispreview: {
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
    },
    createNew: true,
    rules: {
      required: false,
    }
  },
  computed: {
    allowMore() {
      const that = this;
      var allow = true;
      allow = that.pokervalue.maxCardsPerHand > that.pokervalue.cardsPerHand;
      if (!allow)
        this.pokervalue.manualDealMode = DealModes.NONE;
      else {
        if (this.pokervalue.manualDealMode == DealModes.NONE)
          this.pokervalue.manualDealMode = DealModes.BOTH;
      }
      return allow;
    }
  },
  
  data: function() {
    return {
      JokerTypes: JokerTypes,
      DealModes: DealModes,
      ShuffleModes: ShuffleModes,
      BettingLimits: BettingLimits,
      pokervalue: this.$props.ispreview ? { ...this.$props.rules } : { ...DEFAULT }, 
    };
  },

  beforeMount(){
    // AV: we need to emit on mount in addition to when the value or pokervalue changes. 
    // emit when value changes is done by the watch fnction below. however if no change is
    // made then we end up saving the values already stored in gamedef in create-room.vue rather
    // than the default values for this game 
      this.$emit("input", this.pokervalue);
  },
  
  watch: {
    pokervalue: {
      deep: true,
      handler(newvalue) {
        this.$emit("input", newvalue);
      },
    },
  },
};
</script>