<template id="rummy">
  <b-container>
    <template v-if="ispreview">
      <slot name="previewadditionaloptions"> </slot>
          <b-row>
        <b-col cols="6">
          <h4>Your OWN GAME </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Packs Per Game</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{ localvalue.packsPerGame }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Auto Calculate Packs</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{ localvalue.autoCalculatePacks }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Shuffle Mode</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.shuffleMode }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Max Players</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.maxPlayers }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <label>Cards auto dealt</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{ localvalue.cardsPerHand }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Auto deal mode</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{localvalue.autoDealMode }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Max Cards</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{ localvalue.maxCardsPerHand }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Manual deal mode</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>{{ localvalue.manualDealMode }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Deal for Seat Position</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.mustSeatDeal }}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Hand On Table </label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.showHandOnTable }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Max Swap Cards</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.maxSwap }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Deal Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.hasDealTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Discard Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.hasDiscardTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Declare Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.hasDeclareTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Bust Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.hasBustTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Burn Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.hasBurnTray }}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Community Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.hasCommunityTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Pot</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.hasPot }}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Min Bet</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.minPot}}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Max Bet</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.maxPot }}
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col cols="6">
          <label>Ante</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.ante }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Joker Tray</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.hasJokerTray }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Joker Type</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.jokerType }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Jokers Per Pack</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.pictureJokersPerPack }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Disable Video</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.disableAudioVideo }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label>Hide Audio/Video controls</label>
        </b-col>
        <b-col cols="6" class="text-right">
          <div>
            {{ localvalue.hideAVControls }}
          </div>
        </b-col>
      </b-row>
    </template>

    <template v-else>
      <b-row>
        <b-col>
          <h4>Configure your OWN GAME </h4>
        </b-col>
      </b-row>
      <slot name="additionaloptions"> </slot>
      <!-- Pack Configurations -->
      <b-row>
        <b-col>
          <br />
          <h4 style="”color: #F00″">Number of Packs / Shuffle Mode</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="# of Packs" label-for="packspergame">
            <b-form-input
              id="packspergame"
              placeholder="Packs per game"
              type="number"
              v-model.number="localvalue.packsPerGame"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Add if required" label-for="autoCalculatePacks">
            <b-form-checkbox
              id="autoCalculatePacks"
              v-model="localvalue.autoCalculatePacks"
              name="autoCalculatePacks"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Shuffle Mode" label-for="shufflemode">
            <b-form-select v-model="localvalue.shuffleMode">
              <option
                :key="key"
                v-for="(key, value) in ShuffleModes"
                :value="key"
              >
                {{ value }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Max players" label-for="maxplayers">
            <b-form-input
              id="maxplayers"
              type="number"
              v-model.number="localvalue.maxPlayers"
            />
          </b-form-group>
          <br />
          <br />
        </b-col>
      </b-row>

      <!-- Deal Configurations -->
      <b-row>
        <b-col colspan="2">
          <h4>Deal Configurations</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Auto deal" label-for="cardsperhand">
            <b-form-input
              id="cardsperhand"
              type="number"
              v-model.number="localvalue.cardsPerHand"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Mode" label-for="autoDealMode">
            <b-form-select v-model="localvalue.autoDealMode">
              <option :value="DealModes.FACEUP">FACEUP</option>
              <option :value="DealModes.FACEDOWN">FACEDOWN</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Max Cards" label-for="maxCardsPerHand">
            <b-form-input
              id="maxCardsPerHand"
              type="number"
              v-model.number="localvalue.maxCardsPerHand"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Manual Deal Mode" label-for="manualDealMode">
            <b-form-select v-model="localvalue.manualDealMode"
              :disabled="!allowMore">
              <option :key="key" v-for="(key, value) in DealModes" :value="key">
                {{ value }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Pick to seat" label-for="mustseatdeal">
            <b-form-checkbox
              id="mustseatdeal"
              v-model="localvalue.mustSeatDeal"
              name="mustseatdeal"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Hands on Table" label-for="showHandOnTable">
            <b-form-checkbox
              id="showHandOnTable"
              v-model="localvalue.showHandOnTable"
              name="showHandOnTable"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Max Swap" label-for="maxswap">
            <b-form-input
              id="maxswap"
              type="number"
              v-model.number="localvalue.maxSwap"
            />
          </b-form-group>
          <br />
          <br />
        </b-col>
        <b-col />
      </b-row>

      <!-- Tray Configurations -->
      <b-row>
        <b-col colspan="2">
          <h4>Available Tray Configurations</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Deal" label-for="hasDealTray">
            <b-form-checkbox
              id="hasDealTray"
              v-model="localvalue.hasDealTray"
              name="hasDealTray"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Discard" label-for="hasdiscardtray">
            <b-form-checkbox
              id="hasdiscardtray"
              v-model="localvalue.hasDiscardTray"
              name="hasdiscardtray"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Declare" label-for="hasdeclaretray">
            <b-form-checkbox
              id="hasdeclaretray"
              v-model="localvalue.hasDeclareTray"
              name="hasdeclaretray"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Bust" label-for="hasbusttray">
            <b-form-checkbox
              id="hasbusttray"
              v-model="localvalue.hasBustTray"
              name="hasbusttray"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Burn" label-for="hasburntray">
            <b-form-checkbox
              id="hasburntray"
              v-model="localvalue.hasBurnTray"
              name="hasburntray"
            />
          </b-form-group>
        </b-col>
          <b-col>
          <b-form-group label="Community" label-for="hasCommunityTray">
            <b-form-checkbox
              id="hasCommunityTray"
              v-model="localvalue.hasCommunityTray"
              name="hasCommunityTray"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
         <b-col>
          <b-form-group label="Pot" label-for="hasPot">
            <b-form-checkbox
              id="hasPot"
              v-model="localvalue.hasPot"
              name="hasPot"
            />
          </b-form-group>
          <br />
        </b-col>
        <b-col>
          <b-form-group label="Min Bet" label-for="minPot">
            <b-form-input
              id="minPot"
              type="number"
              v-model.number="localvalue.minPot"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Max Bet" label-for="maxPot">
            <b-form-input
              id="maxPot"
              type="number"
              v-model.number="localvalue.maxBet"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Ante" label-for="ante">
            <b-form-input
              id="ante"
              type="number"
              v-model.number="localvalue.ante"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Joker Configurations -->
      <b-row>
        <b-col>
          <b-form-group label="Has Joker Tray" label-for="hasjokertray">
            <b-form-checkbox
              id="hasjokertray"
              v-model="localvalue.hasJokerTray"
              name="hasjokertray"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Joker Type" label-for="jokertype">
            <b-form-select v-model="localvalue.jokerType">
              >
              <option
                :key="key"
                v-for="(key, value) in JokerTypes"
                :value="key"
              >
                {{ value }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Jokers Per Pack"
            label-for="pictureJokersPerPack"
          >
            <b-form-input
              id="pictureJokersPerPack"
              type="number"
              v-model.number="localvalue.pictureJokersPerPack"
            />
          </b-form-group>
          <hr />
          <hr />
        </b-col>
      </b-row>

      <!-- AV Configurations -->
      <b-row>
        <b-col colspan="2">
          <h4>AV Configurations</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Disable Audio/Video"
            label-for="disableAudioVideo"
          >
            <b-form-checkbox
              id="disableAudioVideo"
              v-model="localvalue.disableAudioVideo"
              name="disableAudioVideo"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Hide AV Controls" label-for="hideAVControls">
            <b-form-checkbox
              id="hideAVControls"
              :disabled="localvalue.disableAudioVideo"
              v-model="localvalue.hideAVControls"
              name="hideAVControls"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>
<script>
import DEBUG from "@/common/DEBUG";
import { JokerTypes } from "@/grpcservices/Game_pb";
import { DealModes } from "@/grpcservices/Game_pb";
import { ShuffleModes } from "@/grpcservices/Game_pb";
import appconfig from "@/config/appconfig";


const DEFAULT = {
  packsPerGame: 1,
  autoCalculatePacks: true,
  pictureJokersPerPack: 2,
  maxPlayers: 9,
  mustSeatDeal: true,

  cardsPerHand: 13,
  maxCardsPerHand: 13,
  maxSwap: 1,
  showHandOnTable: true,
  
  shuffleMode: ShuffleModes.FULL,
  manualDealMode: DealModes.BOTH,
  autoDealMode: DealModes.FACEDOWN,
  isOpenTable:false,

  hasPot: false,
  minPot:10,
  maxPot: 100,
  ante:10,
  
  hasDealTray: true,
  hasDiscardTray: true,
  hasDeclareTray: true,
  hasBustTray: false,
  hasBurnTray: false,
  hasCommunityTray:false,
  hasJokerTray: true,
  jokerType: JokerTypes.OPEN,
  hideAVControls: false,
  disableAudioVideo: false,
};

export default {
  name: "localvalue",
  props: {
    ispreview: {
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
    },
    createNew: true,
    rules: {
      required: false,
    }
  },
  computed: {
    allowMore() {
      const that = this;
      var allow = true;
      allow = that.localvalue.maxCardsPerHand > that.localvalue.cardsPerHand;
      if (!allow)
        this.localvalue.manualDealMode = DealModes.NONE;
      else {
        if (this.localvalue.manualDealMode == DealModes.NONE)
          this.localvalue.manualDealMode = DealModes.BOTH;
      }
      return allow;
    }
  },
  
  data: function() {
    return {
      JokerTypes: JokerTypes,
      DealModes: DealModes,
      ShuffleModes: ShuffleModes,
      localvalue: this.$props.ispreview ? { ...this.$props.rules } : { ...DEFAULT }, 
    };
  },

  beforeMount(){
    // AV: we need to emit on mount in addition to when the value or localvalue changes. 
    // emit when value changes is done by the watch fnction below. however if no change is
    // made then we end up saving the values already stored in gamedef in create-room.vue rather
    // than the default values for this game 
      this.$emit("input", this.localvalue);
  },
  
  watch: {
    localvalue: {
      deep: true,
      handler(newvalue) {
        this.$emit("input", newvalue);
      },
    },
  },
};
</script>